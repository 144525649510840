.App {
	text-align: center;
	font-size: 50px;
	/* font-family: 'Helvetica Neue', Helvetica, sans-serif; */
	display: flex;
	height: 100vh;
	align-items: center;
	justify-content: center;
	/* background-color: #262626; */
}

a {
	color: black;
	text-decoration: none;

	border-bottom: 3px solid black;
}

a:hover {
	border-bottom: none;
}

::selection {
	color: white;
}

@media only screen and (max-width: 600px) {
	.App {
		font-size: 35px;
	}
}